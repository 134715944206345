  .message-container {
    position: absolute;
    top: 0px;
    left: 4rem;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38rem;
    margin: 0 auto;
  }
  
  .message {
    background-color: white;
    padding: 20px;
    border: 1px solid black;
    word-wrap: break-word;
    z-index: 9999;
  }