@import "assets/layout/css/layout-indigo.css";
@import "assets/theme/theme-indigo.css";


.fa,
.fas {
  font-family: "Font Awesome 5 Free";
}

.datatable-demo .p-datatable-responsive .p-datatable-tbody>tr.p-datatable-row>td:last-child .p-column-title {
  display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details>.p-grid {
  border-radius: 3px;
  margin: 0.3em;
  text-align: center;
  padding: 2em 0 2.25em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 24px;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
  margin: 0.25em 0 2em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
  margin-left: 0.5em;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
  margin-left: 0;
}

.dataview-demo .p-dataview .car-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
}

.dataview-demo .p-dataview .car-details>div {
  display: flex;
  align-items: center;
}

.dataview-demo .p-dataview .car-details>div img {
  margin-right: 14px;
}

.dataview-demo .p-dataview .car-detail {
  padding: 0 1em 1em 1em;
  margin: 1em;
}

@media (max-width: 1024px) {
  .dataview-demo .p-dataview .car-details img {
    width: 75px;
  }
}

.layout-container .ultima-menu li ul.layout-submenu-enter-done {
  display: inline;
  overflow: auto;
}

.p-datatable-loading .p-datatable-loading-overlay {
  opacity: 1;
}

.p-datatable .p-datatable-loading .p-datatable-loading-icon {
  animation: 2s linear infinite spin;
  color: #111;
}

body .card-w-title .p-datatable .p-datatable-tbody>tr>td {
  padding: 0;
}

body .card-w-title .p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-tfoot>tr>td {
  padding: 0;
}

/* Menu List start */
body .layout-container .layout-menu li ul li ul ul li a {
  display: inline-flex;
  /*padding-left: 0;*/
}

/* Menu List end */

.biditems-table-title {
  display: flex;
  justify-content: space-between;
}

.taskcodes-table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin-x-1em {
  margin: auto 1em;
}

.hidden {
  display: none;
}

body .p-datatable .p-datatable-tbody .background-color-pink {
  background-color: lightpink;
}

body .p-datatable .p-datatable-tbody>tr:nth-child(even).background-color-pink {
  background-color: pink;
}

.assign-taskcodes-overlaypanel .assignable-taskcodes-overlay-confirmation-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}

.assign-taskcodes-overlaypanel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-datatable-globalfilter-container input {
  width: 200px;
}

.p-datatable-globalfilter-container input::placeholder {
  color: #ffffff;
}

/* Andrei */
.button-menu-button-transition {
  transform: rotate(180deg);
}

.button-menu-container {
  position: relative;
  width: 75px;
  display: inline-block;
}

.button-menu-container-transition {
  padding-left: 20px;
}

.layout-container .topbar .topbar-right #menu-button i {
  transition: transform 0.2s;
}

/* Andrei */

body .p-datatable .p-datatable-tbody>tr:nth-child(even) {
  background-color: #D6D6D6;
}

.p-breadcrumb .p-menuitem-text:hover {
  color: #F57B20;
}

.welcome {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.welcome h1 {
  text-align: center;
  font-size: 3em;
  font-weight: 900;
  color: #003F2D;
}

.welcome .welcome_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome .welcome_image_container .welcome_image {
  width: 740px;
  height: 370px;
  background: url("assets/layout/images/welcome.jpg") top left no-repeat;
  background-size: 740px 370px;
}

.p-datatable table {
  margin-bottom: 1.5em;
}

.p-datatable.p-component.p-datatable-auto-layout {
  overflow-x: hidden;
}

@media (min-width: 512px) and (max-width: 640px) {
  .button-menu-container {
    padding-left: 20px;
    position: relative;
    width: 75px;
    display: inline-block;
  }

  .welcome h1 {
    font-size: 1.75em;
  }

  .welcome .welcome_image_container .welcome_image {
    width: calc(740px / 2);
    height: calc(370px / 2);
    background: url("assets/layout/images/welcome.jpg") top left no-repeat;
    background-size: calc(740px / 2) calc(370px / 2);
  }
}

@media (min-width: 641px) and (max-width: 1240px) {
  .button-menu-container {
    padding-left: 20px;
    position: relative;
    width: 75px;
    display: inline-block;
  }

  .welcome h1 {
    font-size: 3em;
  }

  .welcome .welcome_image_container .welcome_image {
    width: calc(740px / 1.2);
    height: calc(370px / 1.2);
    background: url("assets/layout/images/welcome.jpg") top left no-repeat;
    background-size: calc(740px / 1.2) calc(370px / 1.2);
  }
}

body .p-breadcrumb li:first-child a span:hover {
  color: #F57B20;
}

.biditems-table-title .go_to_cco {
  margin: 0.667em 0px 1em 1em;
  color: #f57b20;
  cursor: pointer;
}

.biditems-title-link{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.biditems-table-title .create_excel {
  font-size: 25px;
  margin: 0.667em 1em 1em 1em;
  color: #f57b20;
  cursor: pointer;
}

.taskcodes-table-title .create_excel{
  font-size: 20px;
  cursor: pointer;
}

.create_excel{
  margin: 1em 1em 0.96em 1em;
}

.excel-plusbutton {
  display: flex;
  align-items: center;
}

.topbar-bell{
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.notification::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 18px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(252, 9, 9);
}