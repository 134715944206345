.taskCode-to-cco-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  z-index: 10000;
  padding: 0 20%;

  &.taskCode-to-cco-popup-visible {
    display: flex;
  }
}

.taskCode-to-cco {
  padding: 1em;

  .card-content-flex {
    display: flex;
    gap: 1em;

    .row-with-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .recover-actions-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    min-width: 200px;

    .recover-calculator {
      display: flex;
      flex-direction: column;
      gap: 1em;

      &>div {
        padding: 1em;
        background: rgba(216, 216, 216, 0.5);
      }

      h4 {
        margin-block-start: 0;
        margin-block-end: 0.5em;
      }

      .percent-input {
        display: flex;
        gap: 0.25em;
        align-items: center;

        input {
          background-color: #ffffff;
          width: 100%;
          max-width: 3em;
        }
      }
    }
  }

  .card-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 1em;
    margin-top: 1em;
  }
  
  .taskCode-to-cco-table {
    
    .p-datatable-thead {
      
      tr {

        th {
          padding: 0.5em !important;
        }
        
        &:first-child {
          th {
            background-color: rgb(186, 223, 245);
          }
        }
        
        &:last-child {
          th {
            font-weight: bold;
          }
        }
      }
    }

    .p-datatable-tbody {

      tr {

        td {
          padding: 0.5em !important;
        }
      }
    }

    .p-datatable-tfoot {

      tr {

        td {
          padding: 0.5em;
          background-color: #FAD8B1;
        }
      }
    }
  }
}