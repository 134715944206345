.Menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}

.layout-container.menu-layout-static .Menu .CurrentMonth {
	color: #003f2d;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;
	overflow: hidden;
	width: 100%;
}

.layout-container.menu-layout-static .Menu .CurrentMonth i {
	margin-right: 1.5rem;
}

.layout-container.menu-layout-static.menu-layout-slim .Menu .CurrentMonth i {
	margin-right: 0;
}

.layout-container.menu-layout-static.menu-layout-slim .Menu .CurrentMonth {
	flex-direction: column;
	text-align: center;
}

.Menu .SortFilterBy {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
}

.Menu .SortFilterBy .SearchInput {
	width: calc(100% - 20px);
	margin: 0 auto;
	display: inherit;
	height: 1.56rem;
}

/* hide the arrows for numerical input */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

select.dropdown {
	width: calc(100% - 20px);
	margin: 0 auto;
	display: inherit;
	height: 1.56rem;
	margin-top: 0.3rem;
}

.Menu .JobList {
	height: calc(100% - 350px);
	width: calc(100% - 20px);
	overflow: auto;
	margin: 1.5rem auto 0 auto;
}

.Menu .JobList a.menuitem {
	display: flex;
	color: #003f2d;
	width: 100%;
	box-sizing: border-box;
	transition: background-color .3s;
	overflow: hidden;
	cursor: pointer;
	padding: 0.5rem 2rem;
	padding-left: 1rem;
}

.Menu .JobList a.menuitem:hover {
	color: #f57b20;
	background-color: #e8e8e8;
	transition: .3s;
}

.Menu .JobList a.menuitem.active-menuitem-routerlink {
	color: #f57b20;
	background-color: #e8e8e8;
}

.Menu .JobList .menuitem i.material-icons {
	margin-right: 0.5rem;
}

.Menu .Logout {
	margin-top: 1.5rem;
}

.menu-layout-slim .layout-menu .Menu button{
	padding-left: 14px;
	width: 58px;
}


@media(min-width: 512px) and (max-width: 1024px) {
	.layout-container .layout-menu {
		width: 125px;
	}

	.Menu .JobList a.menuitem {
		padding-left: 0rem;
	}

	.Menu .JobList .menuitem i.material-icons {
		margin-right: 0.2rem;
	}
}

@keyframes p-progress-spinner-color {
0%, 100% {
		stroke: #B6D8CC;;
	}
}

.LoadingMessage {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media(min-width: 390px) and (max-width: 844px) {
	.layout-menu-static-active .topbar .topbar-left{
		max-width: 0px;
		display: none;
	}
	.layout-menu-static-active .layout-menu{
		max-width: 0px;
		display: none;
	}
}
.mobile{
	display: flex;
	flex-direction: row;
}
@media(min-width: 390px) and (max-width: 844px) {
	.mobile{
		display: flex;
		flex-direction: column;
	}
}