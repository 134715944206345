.button {
  display: inline-block;
  width: 35px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 26px;
}