/* Common */
/* Add your variable customizations of layout here */
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v15-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v15-latin-300.woff2") format("woff2"), url("../fonts/roboto-v15-latin-300.woff") format("woff"), url("../fonts/roboto-v15-latin-300.ttf") format("truetype"), url("../fonts/roboto-v15-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v15-latin-regular.eot#iefix") format("embedded-opentype"), url("../fonts/roboto-v15-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v15-latin-regular.woff") format("woff"), url("../fonts/roboto-v15-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v15-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v15-latin-700.eot#iefix") format("embedded-opentype"), url("../fonts/roboto-v15-latin-700.woff2") format("woff2"), url("../fonts/roboto-v15-latin-700.woff") format("woff"), url("../fonts/roboto-v15-latin-700.ttf") format("truetype"), url("../fonts/roboto-v15-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("../fonts/MaterialIcons-Regular.woff2") format("woff2"), url("../fonts/MaterialIcons-Regular.woff") format("woff"), url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}
/* Utils */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
  }
}
@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
  }
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

*[hidden] {
  display: none;
}

.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #ffffff;
  padding: 1em;
  margin-bottom: 1em;
  box-sizing: border-box;
}
.card.card-w-title {
  padding-bottom: 2em;
}
.card h1 {
  font-size: 1.5em;
  font-weight: 400;
  margin: 1em 0;
}
.card h1:first-child {
  margin-top: 0.667em;
}
.card h2 {
  font-size: 1.375em;
  font-weight: 400;
}
.card h3 {
  font-size: 1.25em;
  font-weight: 400;
}
.card h4 {
  font-size: 1.125em;
  font-weight: 400;
}

.nopad {
  padding: 0;
}
.nopad .ui-panel-content {
  padding: 0;
}

.pi-md-spin {
  -webkit-animation-name: spin;
  animation-name: spin;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.ui-shadow-1 {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ui-shadow-2 {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-shadow-3 {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ui-shadow-4 {
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ui-shadow-5 {
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.p-grid.form-group > div {
  padding: 1em;
}
.p-grid.form-group-m > div {
  padding: 1em;
}

.separator {
  border: 1px solid #bdbdbd;
}

/* Ripple */
/* Ripple Effect Style like Google Material Buttons Effect*/
.ripplelink {
  /* display:block; */
  /*color:#fff;*/
  text-decoration: none;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 0;
}

.ripplelink:hover {
  /*z-index:1000;*/
}

.ink {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.ripple-animate {
  -webkit-animation: ripple 0.65s linear;
  -moz-animation: ripple 0.65s linear;
  -ms-animation: ripple 0.65s linear;
  -o-animation: ripple 0.65s linear;
  animation: ripple 0.65s linear;
}

/* Code Highlight */
.docs pre[class*=language-] {
  padding: 0 !important;
  background-color: #fdfdfd;
  border-left: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.docs pre[class*=language-] > code {
  border-left: 10px solid #7280ce;
  box-shadow: none;
  font-size: 14px;
}

.splash-screen {
  background-color: #B6D8CC;
  height: 100%;
}
.splash-screen .splash-container {
  padding-top: 250px;
  width: 250px;
  margin: 0 auto;
  text-align: center;
}
.splash-screen .logo {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 30px;
  background: url("../images/logo2x.png") top left no-repeat;
  background-size: 200px 30px;
}
.splash-screen .load-bar {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 6px;
  background-color: #ffffff;
}
.splash-screen .bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.splash-screen .bar:nth-child(1) {
  background-color: #003F2D;
  animation: loading 3s linear infinite;
}
.splash-screen .bar:nth-child(2) {
  background-color: #8BC34A;
  animation: loading 3s linear 1s infinite;
}
.splash-screen .bar:nth-child(3) {
  background-color: #FFC107;
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* .login-body {
       background: url("../images/login/login2x.png") top left no-repeat #f7f7f7;
       background-size: 100% auto;
   }*/
}
@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    position: absolute;
    left: 0;
    top: 0;
  }

  #invoice-content .card {
    box-shadow: none;
  }
  #invoice-content .card.invoice-table {
    margin-bottom: 10px;
    background-color: transparent;
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  background-color: #f7f7f7;
  height: 100%;
}
body a {
  text-decoration: none;
}

.layout-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #424242;
  top: 0;
  left: 0;
  z-index: 999999997;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.layout-container .topbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 75px;
  background-color: #B6D8CC;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.layout-container .topbar .logo {
  display: inline-block;
  vertical-align: middle;
  width: 175px;
  height: 65px;
  background: url("../images/logo2x.png") top left no-repeat;
  background-size: 175px 55px;
}
.layout-container .topbar .topbar-left {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 75px;
  width: 250px;
  background-color: #F57B20;
  float: left;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
}
.layout-container .topbar .topbar-right {
  padding: 15px;
  position: relative;
}
.layout-container .topbar .topbar-right #menu-button {
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  height: 36px;
  margin-right: 10px;
  position: relative;
  left: -16px;
  top: 3px;
  background-color: #003F2D;
  cursor: pointer;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.layout-container .topbar .topbar-right #menu-button:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.layout-container .topbar .topbar-right #menu-button i {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 36px;
}
.layout-container .topbar .topbar-right #menu-button i:before {
  content: "chevron_left";
}
.layout-container .topbar .topbar-right #topbar-menu-button, .layout-container .topbar .topbar-right #rightpanel-menu-button {
  display: none;
  color: #ffffff;
  vertical-align: middle;
  height: 36px;
  margin-top: 4px;
  float: right;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.layout-container .topbar .topbar-right #topbar-menu-button i, .layout-container .topbar .topbar-right #rightpanel-menu-button i {
  -moz-transition: color 0.5s;
  -o-transition: color 0.5s;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
  font-size: 36px;
}
.layout-container .topbar .topbar-right #rightpanel-menu-button {
  display: block;
}
.layout-container .topbar .topbar-right #rightpanel-menu-button:hover {
  color: #e8e8e8;
}
.layout-container .topbar .topbar-right #rightpanel-menu-button:focus {
  outline: 0;
}
.layout-container .topbar .topbar-right .topbar-items li button {
  overflow: visible;
  cursor: pointer;
}
.layout-container .topbar .topbar-right .topbar-items li button:focus {
  outline: 0;
}
.layout-container .topbar .topbar-right .topbar-items .search-item input {
  position: relative;
  top: -10px;
  font-size: 14px;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #98a2db, #98a2db);
  border-width: 0;
  padding: 2px;
  color: #ffffff;
}
.layout-container .topbar .topbar-right .topbar-items .search-item input:focus {
  outline: 0 none;
}
.layout-container .topbar .topbar-right .topbar-items .search-item input:focus ~ label {
  top: -5px;
  font-size: 12px;
}
.layout-container .topbar .topbar-right .topbar-items .search-item input.p-filled ~ label {
  display: none;
}
.layout-container .topbar .topbar-right .topbar-items .search-item label {
  color: #ffffff;
  top: 8px;
}
.layout-container .layout-menu {
  overflow: auto;
  position: fixed;
  width: 250px;
  z-index: 99;
    /* Andrei */
    top: 75px;
    /* Andrei */
  height: 100%;
  background-color: #ffffff;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.layout-container .layout-menu .menu-scroll-content {
  padding-bottom: 100px;
}
.layout-container .layout-menu.layout-menu-dark {
  background-color: #424242;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a i, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button i {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a:hover, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button:hover {
  background-color: #676767;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink {
  color: #FABD8F;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink i, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink i {
  color: #FABD8F;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink:hover {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover > i, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink:hover > i {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem button {
  color: #ffffff;
  background-color: #003F2D;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a.active-menuitem-routerlink, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem button.active-menuitem-routerlink {
  color: #ffffff;
  background-color: #003F2D;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a.active-menuitem-routerlink i, .layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem button.active-menuitem-routerlink i {
  color: #ffffff;
}
.layout-container .layout-menu .menuitem-badge {
  position: absolute;
  right: 3em;
  top: 0.75em;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  text-align: center;
  background-color: #003F2D;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.layout-container .layout-menu .layout-menu-tooltip {
  display: none;
  padding: 0 5px;
  position: absolute;
  left: 76px;
  top: 6px;
  z-index: 101;
  line-height: 1;
}
.layout-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
  padding: 6px 8px;
  font-weight: 700;
  background-color: #353535;
  color: #ffffff;
  min-width: 75px;
  white-space: nowrap;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.layout-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #353535;
}
.layout-container.menu-layout-overlay .layout-menu {
  margin-left: -250px;
}
.layout-container.menu-layout-overlay .layout-main {
  margin-left: 0px;
}
.layout-container.menu-layout-overlay.layout-menu-overlay-active .layout-menu {
  z-index: 999999999;
  margin-left: 0px;
}
.layout-container.menu-layout-overlay.layout-menu-overlay-active .layout-mask {
  display: block;
}
.layout-container.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right #menu-button i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.layout-container.menu-layout-overlay .topbar {
  z-index: 999999998;
}
.layout-container.menu-layout-overlay .topbar .topbar-right #menu-button i {
  font-size: 36px !important;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.layout-container .layout-main {
  padding: 75px 0 0 0;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.layout-container .layout-main .layout-content {
  padding: 23px;
}
.layout-container .layout-breadcrumb {
  background-color: #ffffff;
  box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  min-height: 42px;
}
.layout-container .layout-breadcrumb ul {
  margin: 8px 0 0 0;
  padding: 0 0 0 20px;
  list-style: none;
  color: #757575;
  display: inline-block;
}
.layout-container .layout-breadcrumb ul li {
  display: inline-block;
  vertical-align: middle;
  color: #757575;
  text-transform: capitalize;
}
.layout-container .layout-breadcrumb ul li:first-child {
  color: #B6D8CC;
}
.layout-container .layout-breadcrumb ul li:first-child button {
  position: relative;
  top: 2px;
  font-size: 1em;
}
.layout-container .layout-breadcrumb ul li button {
  color: #757575;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options {
  float: right;
  padding: 0px 20px 0 0;
  height: 100%;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options button {
  color: #757575;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options button:hover {
  background-color: #e8e8e8;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options button i {
  line-height: inherit;
}
.layout-container .layout-mask {
  display: none;
}
.layout-container .ultima-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.layout-container .ultima-menu.ultima-main-menu {
  margin-top: 16px;
}
.layout-container .ultima-menu li a, .layout-container .ultima-menu li button {
  font-size: 1em;
  display: block;
  padding: 0.5em 1em;
  color: #003F2D;
  width: 100%;
  box-sizing: border-box;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  overflow: hidden;
  cursor: pointer;
}
.layout-container .ultima-menu li a i, .layout-container .ultima-menu li button i {
  color: #003F2D;
}
.layout-container .ultima-menu li a:hover i, .layout-container .ultima-menu li button:hover i {
  color: #F57B20;
}
.layout-container .ultima-menu li a i:first-child, .layout-container .ultima-menu li button i:first-child {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  font-size: 1.5em;
}
.layout-container .ultima-menu li a i:last-child, .layout-container .ultima-menu li button i:last-child {
  float: right;
  font-size: 18px;
  margin-top: 0.15em;
  margin-right: -0.15em;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.layout-container .ultima-menu li a:hover, .layout-container .ultima-menu li button:hover {
  color: #F57B20;
  background-color: #e8e8e8;
}
.layout-container .ultima-menu li a span, .layout-container .ultima-menu li button span {
  display: inline-block;
  vertical-align: middle;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink, .layout-container .ultima-menu li button.active-menuitem-routerlink {
  color: #003F2D;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink > i, .layout-container .ultima-menu li button.active-menuitem-routerlink > i {
  color: #003F2D;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink:hover, .layout-container .ultima-menu li button.active-menuitem-routerlink:hover {
  color: #F57B20;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink:hover > i, .layout-container .ultima-menu li button.active-menuitem-routerlink:hover > i {
  color: #F57B20;
}
.layout-container .ultima-menu li.active-menuitem > a, .layout-container .ultima-menu li.active-menuitem button {
  color: #F57B20;
  background-color: #e8e8e8;
}
.layout-container .ultima-menu li.active-menuitem > a i, .layout-container .ultima-menu li.active-menuitem button i {
  color: #F57B20;
}
.layout-container .ultima-menu li.active-menuitem > a i:last-child, .layout-container .ultima-menu li.active-menuitem button i:last-child {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.layout-container .ultima-menu li.active-menuitem > ul {
  max-height: 500px;
}
.layout-container .ultima-menu li ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;
  overflow: hidden;
}
.layout-container .ultima-menu li ul.layout-submenu-enter {
  max-height: 0;
  display: block;
}
.layout-container .ultima-menu li ul.layout-submenu-enter-active {
  max-height: 1000px;
  transition: max-height cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
}
.layout-container .ultima-menu li ul.layout-submenu-enter-done {
  display: block;
}
.layout-container .ultima-menu li ul.layout-submenu-exit {
  max-height: 1000px;
  display: block;
}
.layout-container .ultima-menu li ul.layout-submenu-exit-active {
  max-height: 0;
  display: block;
  transition: max-height cubic-bezier(0.86, 0, 0.07, 1) 0.3s;
}
.layout-container .ultima-menu li ul li a, .layout-container .ultima-menu li ul li button {
  padding: 0.5em 2em 0.5em 2em;
}
.layout-container .ultima-menu li ul li a > span, .layout-container .ultima-menu li ul li button > span {
  font-size: 13px;
}
.layout-container .ultima-menu li ul li a i:first-child, .layout-container .ultima-menu li ul li button i:first-child {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.6em;
  font-size: 1.25em;
}
.layout-container .ultima-menu li ul li ul li a, .layout-container .ultima-menu li ul li ul li button {
  padding-left: 3em;
}
.layout-container .ultima-menu li ul li ul ul li a, .layout-container .ultima-menu li ul li ul ul li button {
  padding-left: 4em;
}
.layout-container .ultima-menu li ul li ul ul ul li a, .layout-container .ultima-menu li ul li ul ul ul li button {
  padding-left: 5em;
}
.layout-container .ultima-menu li ul li ul ul ul ul li a, .layout-container .ultima-menu li ul li ul ul ul ul li button {
  padding-left: 6em;
}
.layout-container .ultima-menu li.red-badge > a .menuitem-badge {
  background-color: #F44336;
  color: #ffffff;
}
.layout-container .ultima-menu li.purple-badge > a .menuitem-badge {
  background-color: #4527A0;
  color: #ffffff;
}
.layout-container .ultima-menu li.teal-badge > a .menuitem-badge {
  background-color: #00695C;
  color: #ffffff;
}
.layout-container .footer .footer-text-left {
  float: left;
}
.layout-container .footer .footer-text-right {
  color: #757575;
  float: right;
}
.layout-container .footer .footer-text-right span {
  vertical-align: middle;
  display: inline-block;
}

.ajax-loader {
  font-size: 2em;
  color: #003F2D;
}

@media (min-width: 512px) and (max-width: 1024px) {
  .layout-container .topbar-items {
    float: right;
    margin: 0;
    padding: 5px 0 0 0;
    list-style-type: none;
  }
  .layout-container .topbar-items > li {
    float: right;
    position: relative;
    margin-left: 8px;
  }
  .layout-container .topbar-items > li > button {
    position: relative;
    display: block;
  }
  .layout-container .topbar-items > li > button .topbar-item-name {
    display: none;
  }
  .layout-container .topbar-items > li > button .topbar-badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #003F2D;
    color: #ffffff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-container .topbar-items > li .topbar-icon {
    font-size: 36px;
    color: #ffffff;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .layout-container .topbar-items > li .topbar-icon:hover {
    color: #e8e8e8;
  }
  .layout-container .topbar-items > li > ul {
    position: absolute;
    top: 55px;
    right: 5px;
    display: none;
    width: 250px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .layout-container .topbar-items > li.active-top-menu > ul {
    display: block;
  }
  .layout-container .topbar-items > li .topbar-message img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
  .layout-container.menu-layout-static .layout-menu {
    margin-left: 0;
  }
  /*.layout-container.menu-layout-static .layout-main {*/
  /*margin-left: 250px;*/
  /*}*/
  .layout-container.menu-layout-static.menu-layout-slim .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-container.menu-layout-static.menu-layout-slim .layout-menu {
    margin-left: -250px;
  }
  .layout-container.menu-layout-static.menu-layout-slim .layout-main {
    margin-left: 0;
  }
  .layout-container.menu-layout-static .layout-mask {
    display: none;
  }
  .layout-container.menu-layout-horizontal .topbar {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-horizontal .topbar .topbar-left {
    background-color: #B6D8CC;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-horizontal .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-container.menu-layout-horizontal .layout-menu {
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 75px;
    height: auto;
    background-color: #F57B20;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .layout-container.menu-layout-horizontal .layout-menu .menu-scroll-content {
    padding-bottom: 0px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu.ultima-main-menu {
    margin-top: 0;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li {
    float: left;
    position: relative;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a:hover, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button:hover {
    background-color: #e8e8e8;
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a:hover i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button:hover i {
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink > i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink > i {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink:hover, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink:hover {
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink:hover i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink:hover i {
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul {
    z-index: 100;
    position: absolute;
    top: 35px;
    left: 0px;
    width: 250px;
    background-color: #ffffff;
    height: 0;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li a, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li button {
    padding: 10px 16px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul {
    position: static;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul li a {
    padding-left: 32px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul li a {
    padding-left: 48px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul li a {
    padding-left: 64px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul ul li a {
    padding-left: 80px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul ul ul li a {
    padding-left: 96px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li.active-menuitem > a {
    color: #B6D8CC;
    background-color: #e8e8e8;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li.active-menuitem > ul {
    display: block;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > a {
    color: #ffffff;
    background-color: #003F2D;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > ul {
    height: auto;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark {
    background-color: #424242;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a:hover, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button:hover {
    background-color: #676767;
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a:hover i, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button:hover i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink i, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink i {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink:hover {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover i, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink:hover i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem button {
    color: #ffffff;
    background-color: #003F2D;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li ul {
    background-color: #424242;
  }
  .layout-container.menu-layout-horizontal .layout-menu .menuitem-badge {
    left: 2em;
    top: 0.5em;
  }
  .layout-container.menu-layout-horizontal .layout-menu .active-menuitem .menuitem-badge {
    background-color: #ffffff;
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-main {
    padding-top: 110px;
    margin-left: 0px;
  }
  .layout-container.menu-layout-horizontal .layout-mask {
    display: none;
  }
  .layout-container .topbar {
    /* Andrei */
    left: 0;
    width: 100%;
    /* left: 75px; */
    /* width: calc(100% - 75px); */
    /* Andrei */
  }
  .layout-container .topbar .topbar-left {
    /* Andrei */
    display: none;
    /* Andrei */
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container .topbar .topbar-right #menu-button {
    /* Andrei */
    /* display: none; */
    /* transform: rotate(180deg); */
    /* Andrei */
  }
  .layout-container .layout-menu {
    width: 75px;
    overflow: visible;
    z-index: 100;
    /* top: 0; */
    top: 75px;
  }
  .layout-container .layout-menu .ultima-menu {
    padding: 0;
  }
  .layout-container .layout-menu .ultima-menu > li {
    position: relative;
  }
  .layout-container .layout-menu .ultima-menu > li > a, .layout-container .layout-menu .ultima-menu > li button {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .layout-container .layout-menu .ultima-menu > li > a i:first-child, .layout-container .layout-menu .ultima-menu > li button i:first-child {
    font-size: 1.75em;
    margin-right: 0;
  }
  .layout-container .layout-menu .ultima-menu > li > a span, .layout-container .layout-menu .ultima-menu > li > a .submenu-icon, .layout-container .layout-menu .ultima-menu > li button span, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li button .submenu-icon {
    display: none;
  }
  .layout-container .layout-menu .ultima-menu > li > a:hover + .layout-menu-tooltip, .layout-container .layout-menu .ultima-menu > li button:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-container .layout-menu .ultima-menu > li > ul {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 75px;
    min-width: 200px;
    height: 0;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .layout-container .layout-menu .ultima-menu > li > ul li a, .layout-container .layout-menu .ultima-menu > li > ul li button {
    padding-left: 16px;
  }
  .layout-container .layout-menu .ultima-menu > li > ul li ul li a {
    padding-left: 32px;
  }
  .layout-container .layout-menu .ultima-menu > li > ul li ul ul li a {
    padding-left: 48px;
  }
  .layout-container .layout-menu .ultima-menu > li > ul li ul ul ul li a {
    padding-left: 64px;
  }
  .layout-container .layout-menu .ultima-menu > li > ul li ul ul ul ul li a {
    padding: 80px;
  }
  .layout-container .layout-menu .ultima-menu > li > ul li ul ul ul ul ul li a {
    padding: 96px;
  }
  .layout-container .layout-menu .ultima-menu > li.active-menuitem > a:hover + .layout-menu-tooltip, .layout-container .layout-menu .ultima-menu > li.active-menuitem button:hover + .layout-menu-tooltip {
    display: none;
  }
  .layout-container .layout-menu .ultima-menu > li.active-menuitem > ul {
    height: auto;
  }
  .layout-container .layout-menu.layout-menu-dark .ultima-menu > li > ul {
    background-color: #424242;
  }
  .layout-container .layout-main {
    margin-left: 75px;
  }
  .layout-container .layout-footer {
    margin-left: 75px;
  }
}

@media (min-width: 1025px) {
  .layout-container .topbar-items {
    float: right;
    margin: 0;
    padding: 5px 0 0 0;
    list-style-type: none;
  }
  .layout-container .topbar-items > li {
    float: right;
    position: relative;
    margin-left: 8px;
  }
  .layout-container .topbar-items > li > button {
    position: relative;
    display: block;
  }
  .layout-container .topbar-items > li > button .topbar-item-name {
    display: none;
  }
  .layout-container .topbar-items > li > button .topbar-badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #003F2D;
    color: #ffffff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-container .topbar-items > li .topbar-icon {
    font-size: 36px;
    color: #ffffff;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .layout-container .topbar-items > li .topbar-icon:hover {
    color: #e8e8e8;
  }
  .layout-container .topbar-items > li > ul {
    position: absolute;
    top: 55px;
    right: 5px;
    display: none;
    width: 250px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .layout-container .topbar-items > li.active-top-menu > ul {
    display: block;
  }
  .layout-container .topbar-items > li .topbar-message img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
  .layout-container.menu-layout-static .layout-menu {
    margin-left: 0;
  }
  .layout-container.menu-layout-static .layout-main {
    margin-left: 250px;
  }
  .layout-container.menu-layout-static.layout-menu-static-inactive .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-container.menu-layout-static.layout-menu-static-inactive .layout-menu {
    margin-left: -250px;
  }
  .layout-container.menu-layout-static.layout-menu-static-inactive .layout-main {
    margin-left: 0px;
  }
  .layout-container.menu-layout-static .layout-mask {
    display: none;
  }
  .layout-container.menu-layout-horizontal .topbar {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-horizontal .topbar .topbar-left {
    background-color: #B6D8CC;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-horizontal .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-container.menu-layout-horizontal .layout-menu {
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 75px;
    height: auto;
    background-color: #F57B20;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .layout-container.menu-layout-horizontal .layout-menu .menu-scroll-content {
    padding-bottom: 0px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu.ultima-main-menu {
    margin-top: 0;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li {
    float: left;
    position: relative;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a:hover, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button:hover {
    background-color: #e8e8e8;
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a:hover i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button:hover i {
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink > i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink > i {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink:hover, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink:hover {
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink:hover i, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li button.active-menuitem-routerlink:hover i {
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul {
    z-index: 100;
    position: absolute;
    top: 35px;
    left: 0px;
    width: 250px;
    background-color: #ffffff;
    height: 0;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li a, .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li button {
    padding: 10px 16px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul {
    position: static;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul li a {
    padding-left: 32px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul li a {
    padding-left: 48px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul li a {
    padding-left: 64px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul ul li a {
    padding-left: 80px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul ul ul li a {
    padding-left: 96px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li.active-menuitem > a {
    color: #B6D8CC;
    background-color: #e8e8e8;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li.active-menuitem > ul {
    display: block;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > a {
    color: #ffffff;
    background-color: #003F2D;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > ul {
    height: auto;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark {
    background-color: #424242;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a:hover, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button:hover {
    background-color: #676767;
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a:hover i, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button:hover i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink i, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink i {
    color: #FABD8F;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink:hover {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover i, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li button.active-menuitem-routerlink:hover i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a, .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem button {
    color: #ffffff;
    background-color: #003F2D;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li ul {
    background-color: #424242;
  }
  .layout-container.menu-layout-horizontal .layout-menu .menuitem-badge {
    left: 2em;
    top: 0.5em;
  }
  .layout-container.menu-layout-horizontal .layout-menu .active-menuitem .menuitem-badge {
    background-color: #ffffff;
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-main {
    padding-top: 110px;
    margin-left: 0px;
  }
  .layout-container.menu-layout-horizontal .layout-mask {
    display: none;
  }
  .layout-container.menu-layout-slim .topbar {
    /* Andrei */
    left: 0;
    width: 100%;
    /* left: 75px; */
    /* width: calc(100% - 75px); */
    /* Andrei */

  }
  .layout-container.menu-layout-slim .topbar .topbar-left {
    /* Andrei */
    display: none;
    /* Andrei */
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-slim .topbar .topbar-right #menu-button {
    /* Andrei */
    /* display: none; */
    /* transform: rotate(180deg); */
    /* Andrei */
  }
  .layout-container.menu-layout-slim .layout-menu {
    width: 75px;
    overflow: visible;
    z-index: 100;
    /* top: 0; */
    top: 75px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu {
    padding: 0;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li {
    position: relative;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li button {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a i:first-child, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li button i:first-child {
    font-size: 1.75em;
    margin-right: 0;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a span, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a .submenu-icon, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li button span, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li button .submenu-icon {
    display: none;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a:hover + .layout-menu-tooltip, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li button:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 75px;
    min-width: 200px;
    height: 0;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li a, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li button {
    padding-left: 16px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul li a {
    padding-left: 32px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul li a {
    padding-left: 48px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul ul li a {
    padding-left: 64px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul ul ul li a {
    padding: 80px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul ul ul ul li a {
    padding: 96px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li.active-menuitem > a:hover + .layout-menu-tooltip, .layout-container.menu-layout-slim .layout-menu .ultima-menu > li.active-menuitem button:hover + .layout-menu-tooltip {
    display: none;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li.active-menuitem > ul {
    height: auto;
  }
  .layout-container.menu-layout-slim .layout-menu.layout-menu-dark .ultima-menu > li > ul {
    background-color: #424242;
  }
  .layout-container.menu-layout-slim .layout-main {
    margin-left: 75px;
  }
  .layout-container.menu-layout-slim .layout-footer {
    margin-left: 75px;
  }
}

@media (max-width: 399px) {
  .layout-container .topbar .topbar-right #topbar-menu-button {
    right: 10px;
    margin-right: 25px;
    position: absolute;
    top: 15px;
  }
  .layout-container .topbar .topbar-right #rightpanel-menu-button {
    position: absolute;
    right: 5px;
    top: 15px;
    margin-left: 35px;
  }
}
.layout-config {
  z-index: 1000002;
  position: fixed;
  padding: 0;
  top: 75px;
  display: block;
  right: 0;
  width: 550px;
  z-index: 996;
  height: calc(100% - 75px);
  transform: translate3d(550px, 0px, 0px);
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
  background-color: #ffffff;
}
.layout-config.layout-config-active {
  transform: translate3d(0px, 0px, 0px);
}
.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.layout-config .panel-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.layout-config .panel-items .panel-item {
  margin-right: 1em;
  margin-bottom: 1em;
  text-align: center;
}
.layout-config .layout-config-content {
  position: relative;
  height: 100%;
}
.layout-config .layout-config-content > form {
  height: 100%;
}
.layout-config .layout-config-content .layout-config-button {
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  line-height: 52px;
  border: 1px solid #fafafa;
  background-color: #fafafa;
  text-align: center;
  padding: 0;
  top: 230px;
  left: -51px;
  z-index: -1;
  cursor: pointer;
  color: #B6D8CC;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.layout-config .layout-config-content .layout-config-button i {
  font-size: 32px;
  line-height: inherit;
  cursor: pointer;
  height: 100%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: transform 1s;
  -o-transition: transform 1s;
  -webkit-transition: transform 1s;
  transition: transform 1s;
}
.layout-config .layout-config-content .layout-config-button:hover {
  color: #7280ce;
}
.layout-config .layout-config-close {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  right: 32px;
  top: 10px;
  padding: 0;
  z-index: 999;
  border: 1px solid #e0284f;
  background-color: #e0284f;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-config .layout-config-close i {
  color: #ffffff;
  line-height: inherit;
  font-size: 16px;
  font-weight: bold;
}
.layout-config .layout-config-close:hover {
  background-color: #d44d69;
}
.layout-config .p-col {
  text-align: center;
}
.layout-config .p-tabview {
  border: 0 none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav {
  display: flex;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li {
  vertical-align: bottom;
  top: auto;
  margin: 0;
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  border-bottom: 3px solid transparent;
  outline: none;
  cursor: pointer;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover {
  border-color: #98a2db;
  background-color: #e4e7f6;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover > a {
  color: #1b1c1e;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li.p-highlight {
  border: none;
  border-bottom: 3px solid #B6D8CC;
  background-color: #bec5e8;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li.p-highlight > a {
  color: #1b1c1e;
  cursor: pointer;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li.p-highlight:hover {
  background-color: #e4e7f6;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.layout-config .p-tabview.p-tabview-top > .p-tabview-nav li > a {
  color: #1b1c1e;
  padding: 13px 15px 10px;
  font-weight: bold;
}
.layout-config .p-tabview .p-tabview-panels {
  padding: 1em 0;
  height: 100%;
  overflow: auto;
  border-width: 1px 0 0 0;
  color: #1b1c1e;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.layout-config .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 2em;
}
.layout-config .p-tabview .p-tabview-panels img {
  max-height: 100px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.layout-config .p-tabview .p-tabview-panels button {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.layout-config .p-tabview .p-tabview-panels button:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.layout-config .p-tabview .p-tabview-panels button i {
  font-size: 32px;
  color: #B6D8CC;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #ffffff;
  width: 40px;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 1;
}
.layout-config .p-tabview .p-tabview-panels button.layout-config-option {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  overflow: hidden;
  text-align: center;
}
.layout-config .p-tabview .p-tabview-panels button.layout-config-option:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.layout-config .p-tabview .p-tabview-panels button.layout-config-layout-option img {
  height: 87px;
  width: 109px;
}
.layout-config .p-tabview .p-tabview-panels button.layout-config-layout-option i {
  color: #ffffff;
  position: absolute;
}
.layout-config .p-tabview .p-tabview-panels h1 {
  font-size: 21px;
  font-weight: 600px;
  margin: 0;
  margin-bottom: 10px;
}
.layout-config .p-tabview .p-tabview-panels span {
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-top: 6px;
  margin-bottom: 15px;
}
.layout-config .p-tabview .p-tabview-panels .p-disabled {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  cursor: default;
}
.layout-config .p-tabview .p-tabview-panels .p-disabled:hover {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.layout-config .p-tabview .p-tabview-panels .p-disabled i {
  font-size: 48px;
  color: #B6D8CC;
  background-color: transparent;
  box-shadow: none;
  position: absolute;
}
.layout-config p {
  line-height: 1.5;
  margin-top: 0;
  color: #757575;
}

.blocked-scroll-config {
  overflow: hidden;
}

.layout-rtl .layout-config {
  direction: rtl;
  right: auto;
  left: 0;
  width: 550px;
  transform: translate3d(-550px, 0px, 0px);
}
.layout-rtl .layout-config.layout-config-active {
  transform: translate3d(0px, 0px, 0px);
}
.layout-rtl .layout-config .layout-config-button {
  left: auto;
  right: -51px;
}
.layout-rtl .layout-config .layout-config-close {
  right: auto;
  left: 7px;
}

@media screen and (max-width: 1024px) {
  .layout-config {
    transform: translate3d(100%, 0px, 0px);
  }
  .layout-config.layout-config-active {
    width: 100%;
    transform: translate3d(0px, 0px, 0px);
  }
  .layout-config .layout-config-button {
    left: auto;
    right: -52px;
  }
  .layout-config .layout-config-close {
    right: 10px;
  }
}
/* Add your customizations of layout here */
