.center {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.backdrop {
  backdrop-filter: blur(3px);
}
