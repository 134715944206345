.exception-body.accessdenied-page.logout-page {
	position: relative;
}

.exception-body.accessdenied-page.logout-page .exception-type {
	background-color: #f57b20;
	height: 100px;
	width: 100%;
}

.logout-block {
	display: block;
	width: 35%;
	text-align: center;
	padding: 1rem 0;
	margin: 0 auto;
	z-index: 3;
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 32%;
}

.logout-block .exception-detail {
	margin-bottom: 2rem;
}

.logout-block button {
	background-color: #f57b20;
}

.logout-block button:hover {
	background-color: #fff !important;
	border: 1px solid #f57b20;
	color: #f57b20;
}