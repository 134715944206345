#executive_summary {
	background-color: #fff;
	width: calc(100% - 50px);
	height: calc(100% - 50px);
}

#executive_summary .top_bar {
	display: flex;
	justify-content: space-between;
	padding: 1rem;
	background-color: #b6d8cc;
	color: #fff;
	margin-bottom: 1rem;
	position: relative;
	height: 3rem;
	max-height: 3rem;
}

#executive_summary .middle_info {
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* align-items: center; */
	margin-bottom: 1rem;
}

#executive_summary .block_label {
	margin-bottom: 1rem;
	font-weight: 600;
	border-bottom: 1px solid #d8d8d8;
	width: fit-content;
	display: block;
}

#executive_summary .middle_info .block {
	width: 33.3%;
	border: 1px solid #d8d8d8;
	padding: 0.2rem;
	padding: 0.5rem;
}

#executive_summary .middle_info .block:nth-child(2n+2) {
	margin: 0 1rem;
}

#executive_summary .middle_info .block .content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 320px;
}

#executive_summary .middle_info .block .content .top {
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

#executive_summary .middle_info .block .content .mid {
	display: flex;
	flex-direction: column;
	border-top: 1px solid #000;
	padding: 10px 0;
}

#executive_summary .middle_info .block .content .total {
	display: flex;
	flex-direction: column;
	border-top: 1px solid #000;
	padding-top: 10px;
}

#executive_summary .middle_info .block .content .row {
	display: inherit;
	justify-content: space-between;
	align-items: center;
}

#executive_summary .middle_info .block .content .row .label {
	padding-right: 1rem;
}

#executive_summary input:read-only {
	background-color: #e2e2e2;
	border-color: #d3d3d3;
	padding: 0.2rem;
}

#executive_summary .bottom_info {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

#executive_summary .bottom_info .block {
	width: 50%;
	padding: 0.5rem;
	border: 1px solid #d8d8d8;
}

#executive_summary .bottom_info .block .row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#executive_summary .bottom_info .block .row * {
	width: 33%;
}

#executive_summary .bottom_info .block .table_header {
	display: flex;
	justify-content: space-evenly;
	text-align: center;
	padding-bottom: 0.5rem;
}

#executive_summary .bottom_info .block .table_header .header_item {
	width: 33.3%;
	font-weight: 600;
}

#executive_summary .confirmation_buttons {
	display: flex;
	justify-content: space-between;
	margin: 0 0.5rem;
}

#executive_summary .confirmation_buttons > div {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

#executive_summary .executive_summary_content {
	max-height: calc(100% - 4rem);
  overflow-y: auto;
  padding-bottom: 1rem;
}

#executive_summary .executive_summary_content .input_area {
  width: 100%;
	background-color: rgb(243, 242, 170);
}

.changes-flag{
	font-size: 12px;
}

@media(min-width: 1439px) {
	#executive_summary {
		height: auto;
	}
}