.assign-taskcodes-overlaypanel, .taskcodes-table {

  .task-code-id-cell {
    
    div.taskcode-id-wrapper {
      position: relative;
      width: 100%;
      max-width: 5em;
      padding-right: 0.75em;
      
      &.have-ccos {

        &.clickable-cell:hover {
          cursor: pointer;

          span::before {
            right: 0.3em;
            width: 0.7em;
            height: 0.7em;
            margin-top: -0.35em;
          }
        }

        span::before {
          position: absolute;
          top: 50%;
          right: 0.4em;
          content: "";
          width: 0.5em;
          height: 0.5em;
          margin-top: -0.25em;
          border-radius: 50%;
          background-color: #4CAF50;
          transition: all ease 0.25s;
        }
      }
    }
  }
}

.taskcodes-table {
  .task-code-id-cell {
    div.taskcode-id-wrapper {
      &.have-ccos {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}