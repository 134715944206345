.topbar {
  
  .topbar-right {
    display: flex;
    align-items: center;
    
    .topbar-buttons {
      display: flex;
      justify-content: space-between;
      margin-right: 1em;

      button {
        width: 196px;
      }
    }
  }
}